import axios from 'axios'
import type { AxiosInstance, AxiosError } from 'axios'
import type { HHRequestInterceptors, HHRequestConfig } from './type'
import { ElLoading } from 'element-plus'
import { ILoadingInstance } from 'element-plus/es/components/loading/src/loading.type'
import router from '@/router'
import { ElMessage } from 'element-plus'

const DEFAULT_LOADING = true

class HHRequest {
  instance: AxiosInstance
  interceptors?: HHRequestInterceptors
  isShowLoading?: boolean
  loading?: ILoadingInstance

  constructor(config: HHRequestConfig) {
    // 1.创建axios实例
    this.instance = axios.create(config)

    // 2.保存基本信息
    this.interceptors = config.interceptors
    this.isShowLoading = config.isShowLoading ?? DEFAULT_LOADING

    // 3.使用拦截器
    // 从config中取出的拦截器是对应实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )

    // 添加所有的实例都有的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        if (this.isShowLoading) {
          this.loading = ElLoading.service({
            lock: true,
            text:
              window.location.host === 'es.iioe.org'
                ? 'Cargando'
                : 'загружается',
            background: 'rgba(0, 0, 0, .5)'
          })
        }
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
    this.instance.interceptors.response.use(
      (res) => {
        this.loading?.close()
        const data = res.data
        // return data
        if (res.status === 200 || res.status === 204) {
          return data
        } else {
          return Promise.reject(res)
        }
      },
      (err: AxiosError) => {
        this.loading?.close()
        if (err.response?.status === 404) {
          console.log('404错误')
        }
        return Promise.reject(err)
      }
    )
  }

  request<T = any>(config: HHRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 1.单个请求对请求config的处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }

      // 2.判断是否需要显示loading
      if (config.isShowLoading === false) {
        this.isShowLoading = config.isShowLoading
      }

      // 3. 请求处理
      this.instance
        .request<any, T>(config)
        .then((res) => {
          // 单个请求对数据的处理
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 2. 将showLoading设置为true，这样就不会印象下一次请求
          this.isShowLoading = DEFAULT_LOADING

          // 3将结果resolve返回出去
          resolve(res)
        })
        .catch((err: AxiosError) => {
          // 将showLoading设置为true，这样就不会影响下一次请求
          this.isShowLoading = DEFAULT_LOADING
          // config.interceptors?.responseInterceptorCatch
          // // token过期或者失效
          if (err.response?.status === 401) {
            console.log('401错误', err.response?.status)
            router.push('/login')
          }
          if (err.response?.status === 422) {
            console.log('422错误', err.response?.data)
            let errors = err.response?.data
            const messages = []
            if (typeof errors !== 'object') {
              errors = [errors]
            }
            for (const name in errors) {
              messages.push(errors[name])
            }
            ElMessage.error(messages.join('<br />'))
          }
          reject(err)
          return err
        })
    })
  }

  get<T = any>(config: HHRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' })
  }

  post<T = any>(config: HHRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' })
  }

  delete<T = any>(config: HHRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' })
  }

  patch<T = any>(config: HHRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PATCH' })
  }

  put<T = any>(config: HHRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PUT' })
  }
}

export default HHRequest
