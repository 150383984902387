import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Cookies from 'js-cookie'
import hhRequest from '@/service'
import { IDataType } from '@/service/types'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    // 懒加载
    component: () => import('@/views/login/login.vue'),
    beforeEnter: (to, from) => {
      const {
        query: { redirect }
      } = to
      const { path, fullPath } = from

      if (
        !redirect &&
        path !== '/' &&
        path !== '/register' &&
        path !== '/forgetpwd'
      ) {
        return {
          path: '/login',
          query: { redirect: encodeURIComponent(fullPath) }
        }
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/register.vue')
  },
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('@/views/forgetpwd/forgetpwd.vue')
  },
  {
    path: '/privacy-agreement',
    name: 'privacyAgreement',
    component: () =>
      import('@/views/main/cpns/privacy-agreement/privacy-agreement.vue')
  },
  {
    path: '/',
    component: () => import('@/views/main/index.vue'),
    children: [
      {
        path: '',
        name: 'main',
        meta: { title: 'homePage' },
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/main/main.vue'),
        children: [
          {
            path: '',
            name: 'home',
            component: () => import('@/views/main/cpns/home-page/home-page.vue')
          },
          {
            path: 'profile',
            name: 'profile',
            meta: { requiresAuth: true },
            component: () => import('@/views/main/cpns/profile/profile.vue')
          },
          {
            path: 'profile-info',
            name: 'profileInfo',
            meta: { requiresAuth: true },
            component: () =>
              import('@/views/main/cpns/profile/cpns/profile-info.vue')
          },
          {
            path: 'profile-rebind-email',
            name: 'profileRebindEmail',
            meta: { requiresAuth: true },
            component: () =>
              import('@/views/main/cpns/profile/cpns/profile-rebind-email.vue')
          },
          {
            path: 'profile-info-edit',
            name: 'profileInfoEdit',
            meta: { requiresAuth: true },
            component: () =>
              import('@/views/main/cpns/profile/cpns/profile-info-edit.vue')
          },
          {
            path: 'profile-change-password',
            name: 'profileChangePassword',
            meta: { requiresAuth: true },
            component: () =>
              import(
                '@/views/main/cpns/profile/cpns/profile-change-password.vue'
              )
          },
          {
            path: 'course',
            name: 'course',
            meta: { title: 'course' },
            component: () => import('@/views/main/index.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/main/cpns/course/course.vue')
              },
              {
                path: ':urlKey',
                component: () =>
                  import('@/views/main/cpns/course/course-detail.vue')
              },
              {
                path: 'category/:cate',
                component: () =>
                  import('@/views/main/cpns/course/course-category.vue')
              }
            ]
          },
          {
            path: 'training',
            name: 'training',
            meta: { title: 'training' },
            component: () => import('@/views/main/index.vue'),
            children: [
              {
                path: '',
                component: () =>
                  import('@/views/main/cpns/training/training.vue')
              },
              {
                path: ':urlKey',
                component: () =>
                  import('@/views/main/cpns/training/training-detail.vue')
              }
            ]
          },
          {
            path: 'partners',
            name: 'partners',
            meta: { title: 'partners' },
            component: () => import('@/views/main/index.vue'),
            children: [
              {
                path: '',
                component: () =>
                  import('@/views/main/cpns/partners/partners.vue')
              },
              {
                path: ':id',
                component: () =>
                  import('@/views/main/cpns/partners/partners-detail.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/course/content/:urlKey/chapter/:chapterId/lesson/:lessonId',
    name: 'courseContent',
    meta: { requiresAuth: true },
    component: () => import('@/views/main/cpns/course/course-content.vue')
  },
  {
    path: '/training/:urlKey/register',
    name: 'trainingRegister',
    meta: { requiresAuth: true },
    component: () => import('@/views/main/cpns/training/training-register.vue')
  },
  {
    path: '/training/:urlKey/learn',
    name: 'trainingLearn',
    meta: { requiresAuth: true },
    component: () => import('@/views/main/cpns/training/training-learn.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes
})

// 导航守卫
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  // const store = useStore()
  let newStore = JSON.parse(JSON.stringify(store.state))
  // console.log(
  //   'userData--------hh',
  //   newStore,
  //   localCache.getCache('token'),
  //   // Cookies.get('userIdentity'),
  //   !newStore.login.user
  // )
  if (Cookies.get('userIdentity') && !newStore.login.user) {
    try {
      const data = await hhRequest.get<IDataType>({
        url: '/auth/current'
      })
      // console.log('userData======hh', data)
      store.commit('login/setUser', data)
    } catch {
      store.commit('login/logout')
    }
  }
  newStore = JSON.parse(JSON.stringify(store.state))
  if (to.meta.requiresAuth && !newStore.login.user) {
    next('/login')
  } else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
