import { App } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  formatDurationString,
  formatDurationSecondString,
  formatTimeWithString
} from '@/utils/data-format'
import { isMobile } from '@/utils/public-methods'

export default function registerProperties(app: App) {
  app.config.globalProperties.$filters = {
    // 全球化
    hh_t(value: string) {
      const { t, te } = useI18n()
      // 判断是否是需要国际化的value
      if (te(value.toString())) {
        return t(value)
      }
      return undefined
    },

    formatDuration(value: number) {
      return formatDurationString(value)
    },

    formatSecond(value: number, format?: string) {
      return formatDurationSecondString(value, format)
    },

    formatString(value: string, format?: string) {
      return formatTimeWithString(value, format)
    }
  }

  app.config.globalProperties.$isMobile = isMobile()
}
