import { Module } from 'vuex'
import { IRootState } from '../types'
import { Data, DataItem } from './types'
import {
  getCountriesRequest,
  getIdentityRequest
} from '@/service/profile/profile'

const profileModule: Module<Data, IRootState> = {
  namespaced: true,
  state() {
    return {
      countries: [],
      identities: []
    }
  },
  mutations: {
    changeCountries(state, countries: DataItem[]) {
      state.countries = countries
    },
    changeIdentities(state, identities: DataItem[]) {
      state.identities = identities
    }
  },
  getters: {
    getCountriesData(state) {
      return state.countries
    },
    getIdentitiesData(state) {
      return state.identities
    }
  },
  actions: {
    async getCountriesDataAction({ commit }) {
      const countries = await getCountriesRequest()
      commit('changeCountries', countries)
    },
    async getIdentitiesDataAction({ commit }) {
      const identities = await getIdentityRequest()
      commit('changeIdentities', identities)
    }
  }
}

export default profileModule
