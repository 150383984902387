import { Module } from 'vuex'
import { IRootState } from '../types'
import { IPartnersState } from './types'
import {
  getPartnersRequest,
  getAllTeacherRequest
} from '@/service/partners/partners'

const PartnersModule: Module<IPartnersState, IRootState> = {
  namespaced: true,
  state() {
    return {
      partnersList: {},
      teacherData: {}
    }
  },
  mutations: {
    changePartnersList(state, partnersList: any) {
      state.partnersList = partnersList
    },
    changeTeacherData(state, teacherData: any) {
      state.teacherData = teacherData
    }
  },
  getters: {
    getPartnersListData(state) {
      return state.partnersList
    },
    getTeacherData(state) {
      return state.teacherData
    }
  },
  actions: {
    async getPartnersRequestAction({ commit }, payload: any) {
      const partnersResult = await getPartnersRequest(payload)
      commit('changePartnersList', partnersResult)
    },
    async getTeacherRequestAction({ commit }, payload: any) {
      const teacherResult = await getAllTeacherRequest(payload)
      console.log('hh======teacherResult', teacherResult)
      commit('changeTeacherData', teacherResult)
    }
  }
}

export default PartnersModule
