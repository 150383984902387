import hhRequest from '..'
import { IDataType } from '../types'

export function getCountriesRequest() {
  return hhRequest.get<IDataType>({
    url: '/data/country'
  })
}

export function getIdentityRequest() {
  return hhRequest.get<IDataType>({
    url: '/data/identity'
  })
}
