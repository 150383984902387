export default {
  // 登录与注册有关
  login: '登录',
  register: '注册',
  forgetPassword: '忘记密码',
  firstName: '姓',
  lastName: '名',
  birthday: '出生日期',
  identity: '身份信息',
  position: '职务',
  country: '选择国家/地区',
  verificationCode: '验证码',
  sendVerificationCode: '发送验证码',
  toResend: '重新发送',
  password: '密码',
  confirPassword: '确认密码',
  enterPasswordAgain: '请再次输入密码',
  resetPassword: '重置密码',
  setNewPassword: '请这是新密码',
  passwordRule:
    '密码长度应为 8-16 个字符，需同时包含英文字母与数字，且不能包含特殊字符。',
  loginPWDRule: '密码长度为6-32个字符',
  passwordNotMatch: '再次输入密码与上一次不符',
  emailAddressVerification: '邮箱验证',
  keepTheLogin: '15天内保持登录',
  noAccount: '没有账号？',
  mustEmail: '邮箱不能为空',
  ensure: '为保证您的账户安全，修改密码前请先进行邮箱验证',
  male: '男',
  female: '女',
  next: '下一步',
  agree: '同意',
  gender: '性别',
  property: '使用隐私条款和隐私声明',
  haveCode: '已经有账号？',
  editProfile: '编辑个人资料',
  changeEmail: '更换绑定邮箱',
  changePassword: '修改密码',
  // 个人中心菜单
  personalCenter: '个人中心',
  logout: '退出登录',
  hello: '你好',
  unauthorized: '未认证',
  personalInformation: '个人信息',
  studyPlan: '学习计划',
  historicalRecord: '历史记录',
  myCertificate: '我的证书',
  myClass: '我的班级',
  saveProfile: '保存资料',
  firstNameNotEmpty: '请填写姓',
  lastNameNotEmpty: '请填写名',
  birthdayNotEmpty: '请填写出生日期',
  enterValidEmail: '请输入有效邮箱',
  verificationCodeNotEmpty: '验证码不能为空',
  passwordNotEmpty: '密码不能为空',
  // 主页
  contact: '联系方式',
  contactUs: '联系我们',
  email: '电子邮箱',
  newEmail: '新电子邮箱',
  address: '地址',
  homePage: '主页',
  course: '课程',
  training: '培训',
  partners: '合作伙伴',
  iioePower: '强大的教师ICT能力实训平台',
  iioeResponsibilities: '赋能培养未来技能的高等教育集群',
  bannerName: '国际网络教育学院',
  bannerDesc: '借助“一带一路”倡议，增加发展中国家获取优质高等教育的机会',
  //main-top
  mainTopTitle: 'IIOE 助您达成未来教师全技能成就',
  mainTopDesc1: '学习优质前沿课程、掌握实用 web 教学工具',
  mainTopDesc2: '通过 ICT 能力测评见证自我提升过程',
  mainTopDesc3: '参与线上实训项目、获得线下培训机会',
  mainTopDesc4: '共建本地优质学习资源与 IIOE 共成长',
  bannerTopDesc1:
    'IIOE了解每个用户都有自身的学习需求。为满足这一点，IIOE推出了视频课模块。在这里，用户们可以随时随地观看由IIOE及其合作伙伴分享的免费视频课。不仅如此，用户们还可以将视频课添加进自己的学习计划中，从而生成属于自己的，合适自己的学习路径。',
  bannerTitle1: '随时随地学习新知识！',
  bannerLabel1: '海量免费视频课 & 个性化学习体验。',
  bannerBtn1: '现在就去看看',
  bannerTopDesc2:
    '帮助我们的用户提高教师ICT能力是IIOE的主旨。基于此，IIOE邀请了在此领域的领航人们帮助我们共同研发适合IIOE用户的课程体系。用户们还可以通过参与实时直播，线上答疑会等与导师互动。',
  bannerTitle2: 'IIOE培训课程系列',
  bannerLabel2: '成体系的前沿ICT培训课程 & 更多惊喜',
  bannerBtn2: '现在就去注册',
  //main-bottom
  mainBottomTitle: '快速了解IIOE',
  iioeDesc:
    'IIOE作为一项全球合作动议，旨在提高发展中国家高校教师的能力，以增加获得优质高等教育的机会。IIOE的每位合作伙伴都扮演着重要角色，共同确保IIOE的责任制度和可持续性。',
  docName: '李铭',
  intro: '联合国教科文组织高等教育创新中心主任',
  featureTitle: 'IIOE的特点',
  featureContent:
    '国际网络教育学院项目是由联合国教科文组织高等教育创新中心（中国深圳）与亚太、非洲地区发展中国家的伙伴院校、中国的伙伴院校和企业共同发起设立的。IIOE旨在帮助发展中国家的伙伴院校增强机构能力，从而提升获取优质高等教育的机会。',
  help: '帮助',
  construct: '建设',
  provide: '提供',
  build: '搭建',
  utilize: '利用',
  upgrade: '提升',
  mainBottomDesc1: '伙伴大学教师提升个人ICT能力',
  mainBottomDesc2: '伙伴大学开展在线和混合式学习的生态',
  mainBottomDesc3: '伙伴大学所需的优质在线课程资源',
  mainBottomDesc4: '高等教育和就业市场技能需求的桥梁',
  mainBottomDesc5: '人工智能技术促进伙伴院校的人才个性化培养',
  mainBottomDesc6:
    '发展中国家女性教师和学生以及边缘化群体获得优质高等教育的机会',

  // 课程 course
  recommendedTitle: '推荐课程',
  cooperationTitle: '合作课程',
  allCourseTitle: '所有课程',
  all: '所有',
  emptyContent: '课程内容将很快更新',
  more: '更多',
  week: '周',
  courses: '开设课程',
  copyrightOwners: '版权方',
  introduce: '介绍',
  theOutline: '大纲',
  teacher: '教师',
  hasJoined: '已加入',
  join: '加入学习计划',
  introduction: '简介',
  school: '学时',
  rTeacher: '授课老师',
  ecologicalSystem:
    '提供前沿的信息通信技术（ICT）相关课程资源，打造共建共享课程资源的生态系统。',
  teacherNumber: '教师数量',

  // 培训 training
  home: 'HOME',
  inTheReZhao: '热招中',
  registrationTitle: '培训报名信息登记',
  nameOfTheCertificate: '证书展示姓名',
  educationBackground: '您当前的学历',
  jobTitle: '工作职称',
  TheInaugurationOfAgency: '就职机构',
  major: '任教的专业',
  certificationSystem: 'IIOE教师ICT能力认证体系',
  abilityToCertification: '点击下面的徽章了解如何获得对应的能力认证。',
  primary: '初级',
  theIntermediate: '中级',
  senior: '高级',
  special: 'ICT专项',
  hybrid: '混合式教学',
  administrativeManagement: '行政管理',
  obtain: '随时随地获得证书',
  topResources: '顶级资源',
  theTeacher: '老师',
  allOf: '全部',
  packUp: '收起',
  general: '通用',
  researchOriented: '科研方向',
  applicationOriented: '教育应用方向',
  openForRegistration: '开放注册',
  // 学历
  doctor: '博士',
  master: '硕士',
  undergraduate: '本科',
  juniorCollege: '大专',
  technicalSecondary: '中专',
  // 工作职称
  other: '其他',
  teachingAssistant: '助教',
  lecturer: '讲师',
  seniorLecturer: '高级讲师',
  assistantProfessor: '助理教授',
  associateProfessor: '副教授',
  professor: '教授',
  professorProfessors: '讲席教授',
  honoraryProfessors: '荣誉教授',
  learn: '向全球顶尖学者和教育实践者学习',
  relatedCourses: '完成ICT辅助教学与教育管理、大数据、人工智能、云计算等课程',
  forge: '由联合国教科文组织高等教育创新中心与伙伴高等院校共同打造',
  companionUnit: '伙伴单位（院校）:',
  // 任教的专业
  art: '艺术学',
  pedagogy: '教育学',
  management: '管理学',
  aLaw: '法学',
  literature: '文学',
  agronomy: '农学',
  military: '军事学',
  philosophy: '哲学',

  // 培训
  important:
    'IMPORTANT：Please confirm your FULL NAME to be displayed on the certificate.',
  change: 'You may change the name by clicking "Change"',
  submit: 'Please click "Submit Application" to submit',
  certificateName: '证书显示姓名',
  modify: '修改',
  confirm: '确认',
  submitApplication: '提交申请',
  submitBtn: '提交',
  workFinish: '作业完成',
  pleaseInput: '请输入作业内容',
  taskContent: '任务内容',
  beginToLearn: '开始学习',
  signUp: '立即报名',
  classDetail: '班级详情',
  courseOutline: '课程大纲',
  learnContent: '课程内容',
  homework: '作业',
  freeClasses: '免费的ICT能力提升课程',
  custom: '为高等院校教师量身定制',
  buildForManagers: '为全球高等教育教师与管理者打造',
  combination:
    '实现全纳教育目标依赖于引进和使用信息通信技术及辅助技术，并与训练有素的教师和适当教学方法相结合。',
  testReport: '——联合国教科文组织全球教育检测报告（2020）',
  classTime: '开班时间',
  here: '此处',
  click: '点击',
  understand: '，了解培训报名流程',
  trainingInformation: '培训资讯',
  update: '更新您的知识与技能',
  computing: '深入了解云计算、大数据、物联网、人工智能等高新领域',
  digital: '为数字化时代做好准备',
  blending: '提高在线与混合式教学与学习能力',
  earn: '获得证书',
  taught: '由全球顶尖学者和教育实践者授课',
  joinGlobal: '加入全球职业学习社区',
  higher: '与其他高校教师共享资源',
  model: '模块',
  nameOfInstitution: '请输入所在机构名称',
  circleImage: 'circle_en.e357800b',
  // 证书
  order: '确认订单',
  issueTheCertificate: '已收到您提交的证书申请，我们审核后将在4周内颁发证书。',
  emailDownload: '电子证书将会发至您的邮箱，可供下载。',
  note: '备注：颁发的证书设计会根据证书版本设计更新会略有不同。',
  close: '关闭',
  submitAnApplication: '申请证书',
  canNotEmpty: '方框（内容）不能为空',
  sureSubmit: '确定提交作业吗？',
  sure: '确定',
  cancle: '取消',
  // 合作伙伴
  aboutUs: '关于我们',
  interest: '如果您有兴趣与 IIOE 合作，请点击此处。',
  higherEducation: '高等教育院校',
  institutions: '机构',
  // 公共
  watchTheVideo: '观看视频',
  coursePlayer: '课程资源指引',
  nanShanDistrict: '中国广东省深圳市南山区学苑大道1088号',
  links: 'LINKS',

  // 练习题
  choise: '多项选择',
  judge: '判断',
  pre: '上一题',
  nextQ: '下一题',
  right: '正确',
  wrong: '错误'
}
