
import { defineComponent, PropType, ref } from 'vue'

import HhXgPlayer from './hh-xgplayer.vue'

export default defineComponent({
  name: 'videoBox',
  components: {
    HhXgPlayer
  },
  props: {
    url: {
      type: String as PropType<string>,
      required: true
    },
    playerTitle: {
      type: String as PropType<string>,
      default: 'watchTheVideo'
    }
  },
  setup() {
    const dialogVisible = ref(false)
    return {
      dialogVisible
    }
  }
})
