import { computed } from 'vue'
import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {
  const locales = require.context('./config', true, /[A-Za-z0-9-_,\s]+\.ts$/i)
  const messages: any = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

// 注册i18n实例并引入语言文件
// const store = useStore()
// let index = computed(() => store.state.lang.index)
// if (store) {
//   index = computed(() => store.state.lang.index)
// } else {
//   index = 4
// }
// const index = store.state.lang.index
// console.log('jjjjjjj', index)
// let langSrc = 'ru'

// if (index === 5) {
//   langSrc = 'es'
// } else {
//   langSrc = 'ru'
// }
const i18n = createI18n({
  legacy: false,
  // locale: 'zh',
  locale: window.location.host === 'es.iioe.org' ? 'es' : 'ru',
  // locale: 'es',
  messages: loadLocaleMessages()
})

export default i18n
