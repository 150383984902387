import hhRequest from '..'
import { IDataType } from '../types'
import { IPartnersItem } from '@/store/partners/types'
import { ITrainingItem } from '@/store/training/types'

export function getRecommendListRequest() {
  return hhRequest.get<IDataType>({
    url: '/training/recommend'
  })
}

export function getInformationListRequest() {
  return hhRequest.get<IDataType>({
    url: '/training'
  })
}

// 就职机构
export function getTheInaugurationOfAgencyRequest() {
  return hhRequest.get<IDataType<IPartnersItem>[]>({
    url: '/network/all'
  })
}

export function getTrainingApplyRequest(queryInfo: any) {
  return hhRequest.get<IDataType>({
    url: queryInfo.url
  })
}

// 提交报名结果
export function getTrainingSubmitRequest(url: string, queryInfo: any) {
  return hhRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

// 培训详情
export function getTrainingDetailRequest(queryInfo: any) {
  return hhRequest.get<IDataType>({
    url: queryInfo.url
  })
}
export function getTrainingContentRequest(queryInfo: any) {
  return hhRequest.get<IDataType<ITrainingItem['content']>>({
    url: queryInfo.url
  })
}

// 证书
export function getTrainingCertificateRequest(queryInfo: any) {
  return hhRequest.get<IDataType>({
    url: queryInfo.url
  })
}
