import { App } from 'vue'
import 'element-plus/dist/index.css'
import HhForm from '@/base-ui/form'
import TopCarousel from '@/components/top-carousel'
import PublicBottom from '@/components/pulic-bottom'
import PublicMenu from '@/components/public-menu'
import VideoBox from '@/components/video-box'
import MainHeader from '@/components/main-header'
import {
  ElAlert,
  ElAside,
  ElAvatar,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElButton,
  ElCarousel,
  ElCarouselItem,
  ElCheckbox,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElDatePicker,
  ElDialog,
  ElDivider,
  ElDrawer,
  ElForm,
  ElFormItem,
  ElInput,
  ElLink,
  ElOption,
  ElPagination,
  ElPopover,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElRow,
  ElMenu,
  ElMenuItem,
  ElSelect,
  ElScrollbar,
  ElSubMenu,
  ElTabPane,
  ElTabs
} from 'element-plus/lib/components'

const components = [
  HhForm,
  TopCarousel,
  PublicBottom,
  PublicMenu,
  VideoBox,
  MainHeader,
  ElAlert,
  ElAside,
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElRadio,
  ElTabs,
  ElLink,
  ElCheckbox,
  ElRow,
  ElCol,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElRadioGroup,
  ElRadioButton,
  ElTabs,
  ElTabPane,
  ElCarousel,
  ElCarouselItem,
  ElDialog,
  ElPopover,
  ElAvatar,
  ElPagination,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElCollapse,
  ElCollapseItem,
  ElDivider,
  ElScrollbar,
  ElMenu,
  ElSubMenu,
  ElMenuItem,
  ElDrawer
]

export default function (app: App): void {
  for (const component of components) {
    app.component(component.name, component)
  }
}
