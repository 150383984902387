import { INavState, Navs } from './types'
import { IRootState } from '../types'
import { Module } from 'vuex'

const navModule: Module<INavState, IRootState> = {
  namespaced: true,
  state() {
    // 主页导航
    const navs: Navs = [
      {
        title: 'homePage',
        url: '/'
      },
      {
        title: 'course',
        url: '/course'
      },
      {
        title: 'training',
        url: '/training'
      },
      {
        title: 'partners',
        url: '/partners'
      }
    ]
    return {
      navs
    }
  }
}

export default navModule
