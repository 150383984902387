import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a1ff1292")
const _hoisted_1 = { class: "content-nav" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (nav) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        to: nav.url,
        class: "nav-item",
        key: nav
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$filters.hh_t(nav.title)), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}