import { Module } from 'vuex'
import { IRootState } from '../types'
import { ICourseState } from './types'
import {
  getHotCourseRequest,
  getExternalCourseRequest,
  getAllCourseRequest,
  getCourseDetailRequest
} from '@/service/course/course'

const courseModule: Module<ICourseState, IRootState> = {
  namespaced: true,
  state() {
    return {
      hotCourseList: [],
      externalCourseList: [],
      allCourseObject: {},
      courseDetailObject: {
        network: {},
        chapters: []
      }
    }
  },
  mutations: {
    // 给state里面的数据赋值
    changeHotCourseList(state, hotCourseList: any[]) {
      state.hotCourseList = hotCourseList
    },
    changeExternalCourseList(state, externalCourseList: any[]) {
      state.externalCourseList = externalCourseList
    },
    changeAllCourseObject(state, allCourseObject: any) {
      state.allCourseObject = allCourseObject
    },
    changeCourseDetailObject(state, courseDetailObject: any) {
      state.courseDetailObject = courseDetailObject
    }
  },
  getters: {
    hotCourseListData(state) {
      return (state as any)[`hotCourseList`]
    },
    externalCourseListData(state) {
      return (state as any)[`externalCourseList`]
    },
    allCourseData(state) {
      return (state as any)[`allCourseObject`]
    },
    courseDetailData(state) {
      return (state as any)[`courseDetailObject`]
    }
  },
  actions: {
    async getHotCourseListAction({ commit }) {
      // 发送网络请求
      const hotCourseResult = await getHotCourseRequest()

      commit('changeHotCourseList', hotCourseResult)
    },
    async getExternalCourseListAction({ commit }) {
      // 发送网络请求
      const getExternalCourseResult = await getExternalCourseRequest()

      commit('changeExternalCourseList', getExternalCourseResult)
    },
    async getAllCourseListAction({ commit }, payload: any) {
      // 发送网络请求
      const getAllCourseResult = await getAllCourseRequest(payload)

      commit('changeAllCourseObject', getAllCourseResult)
    },
    async getCourseDetailAction({ commit }, payload: any) {
      // 发送网络请求
      const getCourseDetailResult = await getCourseDetailRequest(payload)
      console.log('hh======getCourseDetailResult', getCourseDetailResult)

      commit('changeCourseDetailObject', getCourseDetailResult)
    }
  }
}

export default courseModule
