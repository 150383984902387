
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  PropType
} from 'vue'
import Player from 'xgplayer'

export default defineComponent({
  name: 'hhXgPlayer',
  props: {
    url: {
      type: String as PropType<string>,
      required: true
    },
    autoplay: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    poster: {
      type: String as PropType<string>
    },
    height: {
      type: String as PropType<string>
    }
  },
  setup(props) {
    let player: Player | null = null
    const destroyPlayer = () => {
      if (player) {
        player.pause()
        player.destroy()
        player = null
      }
    }
    const createPlayer = () => {
      destroyPlayer()
      const { url, autoplay, poster, height } = props

      player = new Player({
        id: 'video',
        url,
        autoplay,
        poster,
        height,
        width: '100%',
        fitVideoSize: 'auto'
      })
    }

    onMounted(() => {
      createPlayer()
    })

    onUnmounted(() => {
      destroyPlayer()
    })

    onUpdated(() => {
      createPlayer()
      console.log('createPlayer', player)
    })

    return {}
  }
})
