import { Module } from 'vuex'
import { IRootState } from '../types'
import { ITrainingState, ITrainingItem } from './types'
import {
  getRecommendListRequest,
  getInformationListRequest,
  getTrainingDetailRequest,
  getTrainingApplyRequest,
  getTrainingSubmitRequest,
  getTrainingContentRequest,
  getTrainingCertificateRequest
} from '@/service/training/training'

const trainingModule: Module<ITrainingState, IRootState> = {
  namespaced: true,
  state() {
    return {
      recommendList: [],
      informationList: [],
      trainingDetail: {
        promotion: {
          header: {},
          learningNecessity: {},
          lecturer: {},
          project: {},
          course: []
        },
        trainer: {}
      },
      applyData: {},
      trainingContent: [],
      certificateObject: {}
    }
  },
  mutations: {
    changeRecommendList(state, recommendList: any[]) {
      state.recommendList = recommendList
    },
    changeInformationList(state, informationList: ITrainingItem[]) {
      state.informationList = informationList
    },
    changeTrainingDetail(state, trainingDetail: ITrainingItem) {
      state.trainingDetail = trainingDetail
    },
    changeTrainingContent(state, trainingContent: ITrainingItem['content'][]) {
      state.trainingContent = trainingContent
    },
    changeTrainingApplay(state, applyData: any) {
      state.applyData = applyData
    },
    changeTrainingCertificate(state, certificateObject: any) {
      state.certificateObject = certificateObject
    }
  },
  getters: {
    getRecommendList(state) {
      return state.recommendList
    },
    getInformationList(state) {
      return state.informationList
    },
    getTrainingDetail(state) {
      return state.trainingDetail
    },
    getTrainingContent(state) {
      return state.trainingContent
    },
    getTrainingApplyData(state) {
      return state.applyData
    },
    getTrainingCertificateData(state) {
      return state.certificateObject
    }
  },
  actions: {
    async getRecommendListDataAction({ commit }) {
      const recommendList = await getRecommendListRequest()
      commit('changeRecommendList', recommendList)
    },
    async getInformationListDataAction({ commit }) {
      const informationList = await getInformationListRequest()
      commit('changeInformationList', informationList)
    },
    async getTrainingDetailDataAction({ commit }, payload: any) {
      const trainingDetail = await getTrainingDetailRequest(payload)
      commit('changeTrainingDetail', trainingDetail)
    },
    async getTrainingContentDataAction({ commit }, payload: any) {
      const trainingContent = await getTrainingContentRequest(payload)
      commit('changeTrainingContent', trainingContent)
    },
    async getTrainingApplyDataAction({ commit }, payload: any) {
      const trainingApply = await getTrainingApplyRequest(payload)
      commit('changeTrainingApplay', trainingApply)
    },
    // 提交报名信息
    async trainingSubmitAction({ commit }, payload: any) {
      await getTrainingSubmitRequest(payload.url, payload.user)
    },
    async trainingCertificateAction({ commit }, payload: any) {
      const data = await getTrainingCertificateRequest(payload)
      console.log('data===========hhhhhhhhh', data)
      commit('changeTrainingCertificate', data)
    }
  }
}

export default trainingModule
