
import { computed, defineComponent, ref, watch } from 'vue'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import { useStore } from '@/store'
import NavItem from './nav-item.vue'
import Avatar from './hh-avatar.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'mainHeader',
  components: {
    NavItem,
    Avatar
  },
  setup() {
    const activeName = ref('homePage')
    const titles = [
      {
        title: 'English',
        url: 'http://www.iioe.org?language=en-US'
      },
      {
        title: '中文',
        url: 'http://www.iioe.org?language=zh-CN'
      },
      {
        title: 'Français',
        url: 'http://www.iioe.org?language=fr-FR'
      },
      {
        title: 'عربى',
        url: 'https://ar.iioe.org/'
      },
      {
        title: 'Русский',
        url: 'https://ru.iioe.org/'
      },
      {
        title: 'Español',
        url: 'https://es.iioe.org/'
      }
    ]

    const router = useRouter()
    const loginClick = () => {
      router.push({ path: '/login' })
    }

    const {
      state: { login, nav },
      commit
    } = useStore()
    const user = login.user
    const navs = nav.navs

    const handleClick = (item: string) => {
      activeName.value = item
    }

    let selectIndex = ref(4)
    if (window.location.host === 'ru.iioe.org') {
      selectIndex = ref(4)
    } else {
      selectIndex = ref(5)
    }
    const getSelectIndex = (index: number) => {
      console.log('语言', index)
      selectIndex.value = index
    }

    const drawer = ref(false)

    onBeforeRouteUpdate(() => {
      drawer.value = false
    })

    const logout = () => {
      commit('login/logout')
      location.reload()
    }

    return {
      activeName,
      titles,
      user,
      handleClick,
      loginClick,
      logout,
      navs,
      drawer,
      selectIndex,
      getSelectIndex
    }
  }
})
