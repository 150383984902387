import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

const DEFAULT_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DEFAULT_SECOND = 'm:ss'

// 将天数转换成周数
export function formatDurationString(durationString: number) {
  return dayjs.duration(durationString, 'day').weeks()
}

// 转换成秒
export function formatDurationSecondString(
  durationSecondString: number,
  format: string = DEFAULT_SECOND
) {
  return dayjs.duration(durationSecondString, 'second').format(format)
}

// 时间格式化
export function formatTimeWithString(
  timeStr: string,
  format: string = DEFAULT_TIME_FORMAT
) {
  return dayjs(timeStr).format(format)
}
