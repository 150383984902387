import { IRegisterState } from './types'
import { IRootState } from '../types'
import { Module } from 'vuex'
import { IRegisterAccount } from '@/service/register/types'
import { accountLoginRequest } from '@/service/register/register'
import router from '@/router'
import Cookies from 'js-cookie'

const registerModule: Module<IRegisterState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: ''
    }
  },
  getters: {},
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    }
  },
  actions: {
    async accountRegisterAction({ commit }, payload: IRegisterAccount) {
      // 实现登录逻辑
      const loginResult = await accountLoginRequest(payload)
      const { token } = loginResult
      Cookies.set('userIdentity', token, { expires: 7, domain: 'iioe.org' })
      // Cookies.set('userIdentity', token)
      commit('changeToken', token)

      // 跳转到主页面
      if (token) {
        await router.push('/')
      }
    }
  }
}

export default registerModule
