<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="less">
.app {
  height: 100%;
}
</style>
