export default {
  // 登录与注册有关
  login: 'INICIAR SESIÓN',
  register: 'REGISTRARSE',
  forgetPassword: '¿Se le olvidó la contraseña?',
  firstName: 'Nombre',
  lastName: 'Apellido',
  birthday: 'Fecha de nacimiento',
  identity: 'Identidad',
  position: 'Título',
  country: 'Seleccionar país /región /estado',
  verificationCode: 'Código de Verificación',
  sendVerificationCode: 'Enviar el código de verificación',
  toResend: 'reenviar',
  password: 'Contraseña',
  confirPassword: 'Re-introducir la contraseña',
  enterPasswordAgain: 'introducir la contraseña otra vez',
  resetPassword: 'restablecer la Contraseña:',
  setNewPassword: 'establecer Nueva Contraseña',
  passwordRule:
    'La contraseña debe tener entre 8 a 16 caracteres y debe contener letras y números en inglés, sin caracteres especiales.',
  loginPWDRule: 'La longitud de la contraseña es de 6 a 32 caracteres',
  passwordNotMatch:
    'Introducir la contraseña de nuevo, que no coincide con la última vez',
  emailAddressVerification: 'Verificación del correo electrónico',
  keepTheLogin: 'Permanecer conectado durante 15 días',
  noAccount: '¿No tiene una cuenta aún?',
  mustEmail: 'Dirección del correo electrónico',
  ensure:
    'Para la seguridad de su cuenta, verifique amablemente su correo electrónico y, unavez verificado, haga clic en "Siguiente".',
  male: 'Masculino',
  female: 'Femenino',
  next: 'Siguiente',
  agree: 'Acepto los',
  gender: 'Género',
  property: 'términos y condiciones y la declaración de privacidad',
  haveCode: '¿Ya tiene una cuenta?',
  editProfile: 'Editar el perfil',
  changeEmail: 'cambiar el correo electrónico',
  changePassword: 'cambiar la contraseña',
  // 个人中心菜单
  personalCenter: 'centro personal',
  logout: 'cerrar sesión',
  hello: 'hola',
  unauthorized: 'No autorizado',
  personalInformation: 'INFORMACIÓN PERSONAL',
  studyPlan: 'Plan de estudio',
  historicalRecord: 'Historia',
  myCertificate: 'Certificado de formación',
  myClass: 'Mis clases',
  saveProfile: 'Guardar el perfil',
  firstNameNotEmpty: 'primer nombre no vacío',
  lastNameNotEmpty: 'apellino no vacío',
  birthdayNotEmpty: 'fecha de nacimiento no vacío',
  enterValidEmail: 'Por favor, introduzca el correo correctamente',
  verificationCodeNotEmpty: 'código de verificación no vacío',
  passwordNotEmpty: 'Contraseña no vacío',
  // 主页
  contact: 'tel',
  contactUs: 'contáctenos',
  email: 'Correo electrónico',
  newEmail: 'Nuevo Correo electrónico',
  address: 'dirección',
  homePage: 'Inicio',
  course: 'Cursos',
  training: 'Formaciones',
  partners: 'Socios',
  iioePower:
    'Potente plataforma de formación en competencias TIC para profesores',
  iioeResponsibilities:
    'Empoderar la educación superior para desarrollar las habilidades del futuro',
  bannerName: 'INSTITUTO INTERNACIONAL DE EDUCACIÓN EN LÍNEA (IIOE)',
  bannerDesc:
    'Aprovechar la Iniciativa de la Franja y la Ruta para mejorar el acceso de los países en desarrollo a una educación superior de calidad',
  //main-top
  mainTopTitle:
    'ADQUIRIR LAS HABILIDADES INTEGRALES MÁS DEMANDADAS DE LOS PROFESORES',
  mainTopDesc1:
    'Acceder a cursos innovadores y de calidad, y a herramientas web pragmáticas',
  mainTopDesc2:
    'Ser testigo de la superación personal a través de la evaluación de las competencias TIC',
  mainTopDesc3: 'Participar en capacitación basada en proyectos en línea e ',
  mainTopDesc4:
    'Crear conjuntamente una base individualizada de datos de recursos de aprendizaje digital y crecer con IIOE',
  bannerTopDesc1:
    'IIOE brinda acceso gratuito a varios cursos en vídeo proporcionados por UNESCO-ICHEl y otras IES socias del IIOE. Los profesores de educación superior podrían utilizar estos cursos como un enriquecimiento para su desarrollo profesional y referencias para su propia enseñanza.',
  bannerTitle1: 'Aprender en cualquier momento y en cualquier lugar',
  bannerLabel1:
    'Cursos de vídeo de acceso abierto y experiencia de aprendizaje individualizada',
  bannerBtn1: 'Véalo ahora',
  bannerTopDesc2:
    'El IIOE apoya el esfuerzo global para mejorar las competencias relacionadas con las TIC de los profesores de educación superior. A la luz de este enfoque, IIOE moviliza recursos de sus IES socias y pioneros de la industria para desarrollar unas series de capacitación que contribuyen al desarrollo profesional de sus usuarios como docentes universitarios. UNESCO-ICHEI y otras instituciones anfitrionas emitirán certificados electrónicos gratuitos para quienes hayan completado cada programa.',
  bannerTitle2: 'Series de capacitación del IIOE',
  bannerLabel2:
    'Serie de capacitación certificada sobre educación en aplicaciones, pedagogía facilitada, desarrollos tecnológicos y más',
  bannerBtn2: '¡Inscríbase ahora y explore más!',
  //main-bottom
  mainBottomTitle: 'IIOE EN UN VISTAZO',
  iioeDesc:
    'El IIOE es una iniciativa de colaboración global para fortalecer la competencia docente de las instituciones de educación superior en los países en desarrollo y mejorar el acceso a recursos de educación superior de calidad. La asociación juega un papel importante para garantizar la rendición de cuentas y la sostenibilidad del IIOE.',
  docName: 'Li Ming',
  intro: 'Director, UNESCO-ICHEI',
  featureTitle: 'DESTACADOS DE IIOE',
  featureContent:
    'Iniciado por UNESCO-ICHEI, junto con sus IES socias de educación superior y empresas en países de África, Asia-Pacífico, América Latina y el Caribe? IIOE tiene como objetivo mejorar la capacidad de las IES socias en los países en desarrollo y ampliar el acceso a una educación superior de calidad.',
  help: 'Ayudar',
  construct: 'Asistir',
  provide: 'Proporcionar',
  build: 'Llenar',
  utilize: 'Fomentar',
  upgrade: 'Capacitar',
  mainBottomDesc1:
    'a los profesores en las IES socias con las competencias TIC',
  mainBottomDesc2:
    'las IES socias a desarrollar la capacidad y el ecosistema para el aprendizaje híbrido y en línea',
  mainBottomDesc3: 'cursos en línea de calidad a las IES socias',
  mainBottomDesc4:
    'la brecha entre las ofertas de la educación superior y las demandas del mercado laboral',
  mainBottomDesc5:
    'el aprendizaje personalizado en las IES socias mediante el uso de lA',
  mainBottomDesc6:
    'a los profesores y los estudiantes y grupos marginados con un mayor acceso a una educación superior de calidad',

  // 课程 course
  recommendedTitle: 'Cursos Recomendados',
  cooperationTitle: 'Cursos cooperativos',
  allCourseTitle: 'Todos los cursos',
  all: 'todo',
  emptyContent: 'El contenido del curso se actualizará próximamente',
  more: 'Más',
  week: 'semana',
  courses: 'cursos',
  copyrightOwners: 'Propietarios de derechos de autor',
  introduce: 'intruducir',
  theOutline: 'el contorno',
  teacher: 'profesor',
  hasJoined: 'se ha unido',
  join: 'unir',
  introduction: 'intruducir',
  school: 'horas',
  rTeacher: 'Profesor',
  ecologicalSystem:
    'IIOE ofrece una gran cantidad de cursos en línea de calidad e innovadora relacionados con las TIC y da forma a un ecosistema de contribución conjunta y beneficios compartidos. ¡Todo lo que quiere lo tiene aquí!',
  teacherNumber: 'números de profesores',

  // 培训 training
  home: 'INICIO',
  inTheReZhao: 'reclutando',
  registrationTitle: 'Información de inscripción',
  nameOfTheCertificate: 'Nombre del Certificado',
  educationBackground: 'Calificaciones Académicas Actuales',
  jobTitle: 'Título Profesional',
  TheInaugurationOfAgency: 'Lugar del Empleo',
  major: 'Especialidad',
  certificationSystem:
    'Sistema de certificación de competencias TIC de los docentes del IIOE',
  abilityToCertification:
    'Haga clic en la insignia a continuación para saber cómo obtener la certificación de competencia correspondiente',
  primary: 'Primario',
  theIntermediate: 'Intermedio',
  senior: 'Avanzado',
  special: 'Especial',
  hybrid: 'Híbrido',
  administrativeManagement: 'Gestión Administrativa',
  obtain: 'Obtenga su certificado en cualquier momento y en cualquier lugar',
  topResources: 'CALIDAD SUPERIOR',
  theTeacher: 'Profesores',
  allOf: 'Mostrar todo',
  packUp: 'Contraer',
  general: 'general',
  researchOriented: 'Direcciones de Investigación',
  applicationOriented: 'Direcciones de Educación Aplicada',
  openForRegistration: 'Abierta para el registro',
  // 学历
  doctor: 'Doctorado',
  master: 'Maestría',
  undergraduate: 'Licenciatura',
  juniorCollege: 'Colegio Universitario',
  technicalSecondary: 'Segundario Técnico',
  // 工作职称
  other: 'Otras',
  teachingAssistant: 'Líder',
  lecturer: 'Profesor',
  seniorLecturer: 'Personal académico',
  assistantProfessor: 'Técnico',
  associateProfessor: 'Oficial del gobierno',
  professor: 'Estudiante',
  professorProfessors: 'Investigador',
  honoraryProfessors: 'Profesor',
  // 西语专有
  esOne: 'Catedrático emérito',
  esTwo: 'Catedrático',
  esThree: 'Profesor titular (associate professor)',
  esFour: 'Profesor titular (senior lecturer)',
  esFive: 'Lector (lecturer)',
  esSix: 'Ayudante (TA)',

  learn: 'Aprenda de los destacados académicos y profesionales',
  relatedCourses:
    'Cursos completos sobre pedagogía y gestión educativa asistida por TIC, Big Data, Inteligencia Artificial, Computación en la nube y más.',
  forge: 'Presentado por UNESCO-ICHEI e IIOE',
  companionUnit: 'En colaboración con:',
  // 任教的专业
  art: 'Arte',
  pedagogy: 'Pedagogía',
  management: 'Administración',
  aLaw: 'Derecho',
  literature: 'Literatura',
  agronomy: 'Agricultura',
  military: 'CienciaMilitar',
  philosophy: 'Filosofía',
  computer: 'Informática y Ciencias de la Información',
  otherO: 'Otras',

  // 培训
  important:
    'IMPORTANTE: Confirme que su NOMBRE COMPLETO se mostrará en el certificado.',
  change: 'Puede cambiar el nombre haciendo clic en "Cambiar"',
  submit: 'Haga clic en "Enviar solicitud" para enviar',
  certificateName: 'Nombre del Certificado',
  modify: 'modificar',
  confirm: 'confirmar',
  submitApplication: 'Enviar Aplicación',
  submitBtn: 'Enviar',
  workFinish: 'Trabajo Terminado',
  pleaseInput: 'Intruduce el contenido de tarea por favor',
  taskContent: 'Contenido de Tarea',
  beginToLearn: 'Comenzar A Estudiar',
  signUp: 'Inscribirse',
  classDetail: 'Información de Clases',
  courseOutline: 'Esquema del Curso',
  learnContent: 'Estudiar el Contenido',
  homework: 'Tarea',
  freeClasses: 'Cursos gratuitos de desarrollo',
  custom:
    'de competencias en TIC diseñados a medida para profesores universitarios',
  buildForManagers:
    'Para profesores y administradores de Educación Superior Global',
  combination:
    'La introducción y el uso exitosos de las TIC y la tecnología de asistencia en la educación inclusiva requiere su integración con enfoques pedagógicos apropiados utilizados por profesores bien capacitados',
  testReport:
    '—— (2020) Informe de Seguimiento de la Educación en el Mundo de la UNESCO',
  classTime: 'Inicio de Formación',
  here: 'aquí ',
  click: 'clic',
  understand: '，Conocer el proceso de inscribirse en la capacitación',
  trainingInformation: 'Información',
  update: 'Actualice sus conocimientos y habilidades',
  computing:
    'en computación en la nube, big data, internet de las cosas, inteligencia artificial y más',
  digital: 'Prepárese para la era digital',
  blending: 'de la enseñanza y el aprendizaje en línea y combinados',
  earn: 'Obtenga un certificado',
  taught: 'impartido por académicos y profesionales líderes mundiales',
  joinGlobal: 'Únase a una comunidad global de aprendizaje profesional',
  higher: 'de profesionales de la educación superior por recursos compartidos',
  model: 'Módulo',
  nameOfInstitution: 'introducir el nombre de la institución',
  circleImage: '@/assets/img/main/training/circle_en.e357800e.png',
  // 证书
  order: 'órden',
  issueTheCertificate:
    'La solicitud de certificado enviada por usted ha sido recibida y se emitirá dentro de las 4 semanas posteriores a nuestra revisión.',
  emailDownload:
    'El certificado electrónico se ha enviado a su correo, se puede descargar.',
  note: 'Nota: El diseño del certificado emitido se actualiza de manera ligeramente diferente según el diseño de la versión del certificado.',
  close: 'Cerrar',
  submitAnApplication: 'Solicitar un certificado',
  canNotEmpty: 'no estar vacío',
  sureSubmit: '¿Está seguro de que quiere enviar la tarea?',
  sure: 'Seguro',
  cancle: 'Cancelado',
  // 合作伙伴
  aboutUs: 'Sobre Nosotros',
  interest: 'Si está interesado en asociarse con IIOE, haga clic',
  higherEducation: 'Instituciones de educación superior',
  institutions: 'Insitituciones',
  // 公共
  watchTheVideo: 'VER VÍDEO',
  coursePlayer: 'GUÍA DE RECURSOS DEL CURSO',
  nanShanDistrict:
    'Avenida Xueyuan 1088, Distrito Nanshan, Shenzhen, Guangdong, China',
  links: 'Enlaces',

  // 练习题
  choise: 'Varias opciones',
  judge: 'juzgar',
  pre: 'Anterior',
  nextQ: 'Siguiente',
  right: 'correcto',
  wrong: 'incorrecto'
}
