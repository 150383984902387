import { createApp } from 'vue'
import { globalRegister } from './global'
import { setupStore } from '@/store'
import ElementPlus from 'element-plus'
import ru from 'element-plus/es/locale/lang/ru'
import es from 'element-plus/es/locale/lang/es'
import App from './App.vue'
import VueI18n from '@/lang'
import router from './router'
import store from './store'
import 'lib-flexible'

import MarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@jiker/markdown-it/styles/markdown-it.css'
import baseTheme from '@kangc/v-md-editor/lib/theme/base.js'
import MarkdownItContainer from '@kangc/v-md-editor/lib/utils/markdown-it-container.js'

// 相比于传统的CSS reset，Normalize.css是一种现代的、为HTML5准备的优质替代方案
import 'normalize.css'
import './assets/css/index.less'

MarkdownEditor.use((editor: any) => {
  const { markdownParser } = baseTheme()

  markdownParser
    .use(MarkdownItContainer, {
      type: 'hljs-left',
      blockClass: '',
      defaultTitle: ''
    })
    .use(MarkdownItContainer, {
      type: 'hljs-center',
      blockClass: '',
      defaultTitle: ''
    })
    .use(MarkdownItContainer, {
      type: 'hljs-right',
      blockClass: '',
      defaultTitle: ''
    })

  editor.vMdParser.theme({
    previewClass: 'jiker-markdown-it',
    markdownParser: markdownParser
  })
})

const app = createApp(App)

app.use(ElementPlus, {
  // locale: ru
  locale: window.location.host === 'es.iioe.org' ? es : ru
})

app.use(VueI18n)

app.use(globalRegister)
app.use(store)

setupStore()

app.use(router)

app.mount('#app')
