
import { defineComponent } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const store = useStore()
    const navs = store.state.nav.navs
    return {
      navs
    }
  }
})
