import { ILoginState } from './types'
import { IRootState } from '../types'
import { Module } from 'vuex'
import { accountLoginRequest } from '@/service/login/login'
import Cookies from 'js-cookie'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: ''
    }
  },
  getters: {},
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    },
    logout(state) {
      state.user = undefined
      Cookies.remove('userIdentity', { domain: 'iioe.org' })
      // Cookies.remove('userIdentity')
    }
  },
  actions: {
    async accountLoginAction({ commit }, payload: any) {
      // 实现登录逻辑
      const loginResult = await accountLoginRequest(payload.account)
      const { token } = loginResult
      Cookies.set('userIdentity', token, { expires: 7, domain: 'iioe.org' })
      // Cookies.set('userIdentity', token)
      commit('changeToken', token)

      // 跳转到主页面
      if (token) {
        await payload.router.push(
          decodeURIComponent(payload.redirect as string)
        )
      }
    },
    loadLocalLogin({ commit }) {
      const token = Cookies.get('userIdentity')
      if (token) {
        commit('changeToken', token)
      }
    }
  }
}

export default loginModule
