import { ILinkItem, IIconItem } from '../type'

export const linkConfig: ILinkItem[] = [
  {
    label: 'Tencent Foundation',
    link: 'https://gongyi.qq.com/jjhgy/index.htm'
  },
  {
    label: 'Huawei Talent Online',
    link: 'https://e.huawei.com/cn/talent/#/'
  },
  {
    label: 'Weidong',
    link: 'http://www.wdecloud.com/'
  },
  {
    label: 'CreateView',
    link: 'http://www.createview.com.cn/'
  },
  {
    label: 'iFLYTEK',
    link: 'https://www.iflytek.com/index.html'
  },
  {
    label: 'Jiker',
    link: 'https://www.jiker.com/'
  },
  {
    label: 'UBTech',
    link: 'https://www.ubtrobot.com/cn/'
  },
  {
    label: 'CIO Times',
    link: 'http://www.ciotimes.com/'
  },
  {
    label: 'Codemao',
    link: 'https://www.codemao.cn/'
  },
  {
    label: 'KINGSOFT Office',
    link: 'https://www.wps.com/mac/'
  },
  {
    label: 'Southern University of Science and Technology',
    link: 'https://www.sustech.edu.cn/en/'
  },
  {
    label: 'Xidian University',
    link: 'https://en.xidian.edu.cn/'
  },
  {
    label: 'Shenzhen Polytechnic',
    link: 'https://www.szpt.edu.cn/'
  },
  {
    label: 'Shenzhen Institute of Information Technology',
    link: 'https://www.sziit.edu.cn/'
  }
]

export const iconConfig: IIconItem[] = [
  {
    icon: require('@/assets/img/icon/app-store.svg'),
    link: 'https://apps.apple.com/cn/app/iioe/id1518720735'
  },
  {
    icon: require('@/assets/img/icon/google-play.svg'),
    link: 'https://play.google.com/store/apps/details?id=com.iioe.client'
  },
  {
    icon: require('@/assets/img/icon/facebook.svg'),
    link: 'https://www.facebook.com/unescoichei/'
  },
  {
    icon: require('@/assets/img/icon/linkin.png'),
    link: 'https://www.linkedin.com/company/unesco-ichei/'
  },
  {
    icon: require('@/assets/img/icon/wechat.svg'),
    link: '#'
  },
  {
    icon: require('@/assets/img/icon/email.svg'),
    link: 'mailto:iioe@ichei.org'
  }
]
