
import { defineComponent, PropType, ref, onMounted } from 'vue'
import { IFormItem } from '../types'
import hhRequest from '@/service'
import { useCurrentInstance } from '@/utils/public-methods'

export default defineComponent({
  name: 'hhForm',
  props: {
    rules: {
      type: Object,
      default: () => ({})
    },
    findPwd: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object,
      required: true
    },
    formItems: {
      type: Array as PropType<IFormItem[]>,
      default: () => []
    },
    titleWidth: {
      type: String,
      default: '450px'
    },
    itemStyle: {
      type: Object,
      default: () => ({})
    },
    // 动态决定同一行展示几个item
    colLayout: {
      type: Object,
      default: () => ({
        xl: 6, // >1920 4个
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24
      })
    }
  },
  setup(props, { emit }) {
    // 真正实现了双向绑定
    const handleValueChange = (value: any, field: string) => {
      emit('update:modelValue', { ...props.modelValue, [field]: value })
      emit('getNetworkName', value)
    }

    // 将日历的图标从左边换到右边
    onMounted(() => {
      props.formItems.forEach((item) => {
        if (item.type === 'datepicker') {
          document.getElementsByClassName(
            'el-input__suffix-inner'
          )[0].className += ' el-icon-date'
        }
      })
    })

    const { proxy } = useCurrentInstance()

    // 验证码
    const TIME_COUNT = 60
    let count = ref(0)
    let show = ref(true)
    let timer: any = null
    const sendCode = async () => {
      const email = props.modelValue['email']
      try {
        await hhRequest.post({
          url: props.findPwd ? '/password/code' : '/auth/code',
          data: { email: email }
        })
      } catch (err) {
        return err
      }
      if (!timer) {
        count.value = TIME_COUNT
        show.value = false
        timer = setInterval(() => {
          if (count.value > 0 && count.value <= TIME_COUNT) {
            console.log('hh=======', count.value, show.value)
            count.value--
          } else {
            console.log('hh=======', count.value, show.value)
            show.value = true
            clearInterval(timer)
            timer = null
          }
        }, 1000)
      }
    }

    const changeValue = (value: string) => {
      emit('getNetworkName', value)
    }

    return {
      show,
      count,
      handleValueChange,
      sendCode,
      changeValue
    }
  }
})
