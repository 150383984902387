
import { defineComponent, ref } from 'vue'
import { linkConfig, iconConfig } from '../config/link.config'
import NavItem from '../../main-header/src/nav-item.vue'

export default defineComponent({
  name: 'publicMenu',
  components: {
    NavItem
  },
  setup() {
    const activeName = ref('homePage')
    const items = [
      {
        title: 'contact',
        content: '+86-755-88010925'
      },
      {
        title: 'email',
        content: 'iioe@ichei.org'
      },
      {
        title: 'address',
        content: 'nanShanDistrict'
      }
    ]
    const tabs = ['homePage', 'course', 'training', 'partners']

    const handleClick = (item: string) => {
      activeName.value = item
    }

    return {
      activeName,
      items,
      tabs,
      handleClick,
      linkConfig,
      iconConfig
    }
  }
})
