import hhRequest from '../index'
import { IDataType } from '../types'

// 合作伙伴数据请求
export function getPartnersRequest(queryInfo: any) {
  return hhRequest.get<IDataType>({
    url: '/network',
    params: queryInfo,
    isShowLoading: false
  })
}

export function getAllTeacherRequest(queryInfo: any) {
  return hhRequest.get<IDataType>({
    url: queryInfo.url
  })
}
