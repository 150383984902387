import hhRequest from '../index'
import { IDataType } from '../types'
import { ICourseItem, Paginator } from './types'

export function getHotCourseRequest() {
  return hhRequest.get<IDataType>({
    url: '/course/hot'
  })
}

// 课程详情
export function getCourseDetailRequest(queryInfo: any) {
  return hhRequest.get<IDataType<Paginator<ICourseItem>>>({
    url: queryInfo.url
  })
}

export function getExternalCourseRequest() {
  return hhRequest.get<IDataType>({
    url: '/network/external'
  })
}

export function getAllCourseRequest(queryInfo: any) {
  return hhRequest.get<IDataType<Paginator<ICourseItem>>>({
    url: '/course',
    params: queryInfo
  })
}
