import { Module } from 'vuex'
import { IRootState } from '../types'
import { langIndex } from './types'

const langModule: Module<langIndex, IRootState> = {
  namespaced: true,
  state() {
    return {
      index: 4
    }
  },
  mutations: {
    // 给state里面的数据赋值
    changeLangIndex(state, index: number) {
      state.index = index
    }
  },
  getters: {
    langIndexData(state) {
      return (state as any)[`index`]
    }
  },
  actions: {
    getLangIndex({ commit }, payload: number) {
      commit('changeLangIndex', payload)
    }
  }
}
export default langModule
