
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'topCarousel',
  props: {
    arrow: {
      type: String as PropType<'never' | 'always'>,
      default: 'never'
    },
    height: {
      type: String as PropType<string>,
      default: '460px'
    }
  }
})
