export default {
  // 登录与注册有关
  login: 'Вход в систему',
  register: 'регистрация',
  forgetPassword: 'забыли пароль',
  firstName: 'фамилия',
  lastName: 'имя',
  birthday: 'дата рождения',
  identity: 'информация о личности',
  position: 'должность',
  country: 'выберите страну/регион',
  verificationCode: 'код подтверждения',
  sendVerificationCode: 'отправить проверочный код',
  toResend: 'для повторной отправки',
  password: 'пароль',
  confirPassword: 'подтвердите пароль',
  enterPasswordAgain: 'пожалуйста, введите пароль ещё раз',
  resetPassword: 'заново установите пароль, сбросите пароль',
  setNewPassword: 'сохраните новый пароль',
  passwordRule:
    'пароль содержит от 8 до 16 символов, включая буквы и цифры, и не может содержать специальных символов.',
  loginPWDRule: 'Длина пароля 6-32 символа',
  passwordNotMatch: 'повторный ввод пароля не совпадает с предыдущим',
  emailAddressVerification: 'проверка электронной почты',
  keepTheLogin: 'регистрация действительна в течение 15 дней',
  noAccount: 'нет аккаунта?',
  mustEmail: 'строка для электронной почты должна быть обязательно заполнена',
  ensure:
    'Чтобы обеспечить безопасность вашей учетной записи, пожалуйста, подтвердите свой адрес электронной почты перед сменой пароля',
  male: 'мужчина',
  female: 'женский',
  next: 'следующий шаг',
  agree: 'дать согласие',
  gender: 'Пол',
  property: 'Правила и условия и Заявление о конфиденциальности',
  haveCode: 'У вас есть аккаунт?',
  editProfile: 'редактировать профиль',
  changeEmail: 'изменить электронный адрес',
  changePassword: 'изменить пароль',
  enterValidEmail: 'пожалуйста, введите действительный адрес электронной почты',
  verificationCodeNotEmpty: 'проверочный код не должен быть пустым',
  passwordNotEmpty: 'место для пароля не должно быть пустым',
  // 个人中心菜单
  personalCenter: 'личный кабинет',
  logout: 'выйти из системы',
  hello: 'Здравствуй',
  unauthorized: 'неавторизованный, не подтверждённый пользователь',
  personalInformation: 'личная информация',
  studyPlan: 'учебный план',
  historicalRecord: 'история (в браузере)',
  myCertificate: 'моё удостоверение(сертификат)',
  myClass: 'моя группа',
  saveProfile: 'сохранить профиль',
  firstNameNotEmpty: 'First name should not be empty',
  lastNameNotEmpty: 'Last name should not be empty',
  birthdayNotEmpty: 'Birthday should not be empty',
  // 主页
  contact: 'Тел',
  contactUs: 'Свяжитесь с нами',
  email: 'Электронная почта',
  newEmail: 'новый электронный адрес',
  address: 'адрес',
  homePage: 'Главная страница',
  course: 'Курсы',
  training: 'Тренинги',
  partners: 'Партнеры',
  iioePower:
    'Мощная платформа для повышения квалификации преподавателей в области ИКТ',
  iioeResponsibilities:
    'Расширение возможностей центров высшего образования для развития навыков, которые будут востребованы в будущем',
  bannerName: 'Международный институт онлайн-образования',
  bannerDesc:
    'Использование инициативы «Один пояс, один путь» для расширения доступа к качественному высшему образованию',
  //main-top
  mainTopTitle:
    'IIOE позволяет педагогам приобрести наиболее востребованные навыки',
  mainTopDesc1:
    'Получить доступ к качественным передовым курсам и практичным веб-инструментам',
  mainTopDesc2:
    'Продолжать процесс самосовершенствования с помощью «Оценивания ИКТ- компетентности»',
  mainTopDesc3:
    'Участвовать в проектно- ориентированных тренингах, проводимых как в офлайн-, так и в онлайн-формате',
  mainTopDesc4:
    'Совместно локализовать базу данных цифровых образовательных ресурсов и расти вместе с IIOE',
  bannerTopDesc1:
    'IIOE предоставляет бесплатный доступ к различным видеокурсам, разработанным ЮНЕСКО-ICHEI совместно с институтами-партнерами IIOE. ',
  bannerTitle1: 'Получайте новые знания в любое время и в любом месте!',
  bannerLabel1: 'Видеокурсы открытого доступа',
  bannerBtn1: 'Узнать больше',
  bannerTopDesc2:
    'IIOE поддерживает глобальные усилия по повышению квалификации преподавателей высших учебных заведений в области ИКТ. В свете этого, IIOE мобилизует ресурсы из своих партнерских университетов и предприятий для разработки тренингов, которые способствуют профессиональному развитию своих пользователей - университетских преподавателей. Бесплатные электронные сертификаты будут выдаваться ЮНЕСКО-ICHEI и партнерскими институтами для тех, кто завершил каждую программу.',
  bannerTitle2: 'Серия тренингов от IIOE',
  bannerLabel2:
    'Серия сертифицированных тренингов по применению ИКТ в обучении, педагогике с использованием ИКТ, развитию технологий и др.',
  bannerBtn2: 'Зарегистрируйтесь и узнайте больше',
  //main-bottom
  mainBottomTitle: 'Краткий обзор IIOE',
  iioeDesc:
    'IIOE - это глобальная совместная инициатива, направленная на повышение квалификации преподавателей высших учебных заведений в развивающихся странах и улучшение доступа к качественным ресурсам высшего образования. Партнерство играет важную роль в обеспечении подотчетности и устойчивости IIOE.',
  docName: 'Ли Мин',
  intro:
    'Директор Международного центра инноваций в области высшего образования под эгидой ЮНЕСКО',
  featureTitle: 'Особенности IIOE',
  featureContent:
    'IIOE был инициирован ЮНЕСКО-ICHEI совместно со своими партнерскими вузами и предприятиями в странах Африки и Азиатско-Тихоокеанского региона. Он стремится повысить потенциал партнерских вузов в развивающихся странах и расширить доступ к качественному высшему образованию.',
  help: 'Помогать',
  construct: 'Содействовать',
  provide: 'Предоставпять',
  build: 'Устранять',
  utilize: 'Поощрять',
  upgrade: 'Предоставлять',
  mainBottomDesc1:
    'преподавателям вузов-партнеров повысить свою компетенцию в области ИКТ',
  mainBottomDesc2:
    'вузам-партнерам в развитии потенциала и создании экосистемы для онлайн и смешанного обучения',
  mainBottomDesc3: 'вузам-партнерам качественные онлайн курсы',
  mainBottomDesc4:
    'разрыв в спросе и предложении между сектором высшего образования и рынком труда',
  mainBottomDesc5:
    'персонализированное образование в партнерских вузах с помощью ИИ',
  mainBottomDesc6:
    'женщинам-преподавателям и студенткам, а также маргинализованным группам более широкий доступ к качественному высшему образованию',
  // 课程 course
  recommendedTitle: 'Рекомендованные курсы',
  cooperationTitle: 'Совместные курсы',
  allCourseTitle: 'Все курсы',
  all: 'все',
  emptyContent: 'Содержание курса будет обновлено в ближайшее время',
  more: 'больше',
  week: 'недели',
  courses: 'предложенные курсы',
  copyrightOwners: 'авторское право участников',
  introduce: 'вступление, знакомство',
  theOutline: 'основные положения, тезисы',
  teacher: 'преподаватель',
  hasJoined: 'Уже присоединился',
  join: 'Присоединяйтесь к учебному плану',
  introduction: 'введение',
  school: 'школьные часы',
  rTeacher: 'преподаватель, читающий курс',
  ecologicalSystem:
    'IIOE предоставляет большое количество качественных передовых онлайн-курсов по ИКТ и формирует экосистему для реализации принципа «совместного вклада и общих интересов». Здесь есть все что вам нужно!',
  teacherNumber: 'количество учителей',

  // 培训 training
  home: 'Главная страница',
  inTheReZhao: 'Тренинги, вызывающие огромный интерес',
  registrationTitle: 'Форма для регистрации на бучение',
  nameOfTheCertificate: 'Ваше полное имя в сертификате',
  educationBackground: 'Ваша ученая степень',
  jobTitle: 'Ваша должность',
  TheInaugurationOfAgency: 'Ваша организация',
  major: 'Ваша специальность',
  certificationSystem: 'Система сертификации преподавателей IIOE в области ИКТ',
  abilityToCertification:
    'Нажмите на значок ниже, чтобы узнать, как получить соответствующий сертификат компетентности',
  primary: 'Продвинутый уровень',
  theIntermediate: 'Средний уровень',
  senior: 'высокий уровень',
  special: 'Специальный проект по ИКТ',
  hybrid: 'Смешанное обучение',
  administrativeManagement: 'Администрация',
  obtain: 'Получение сертификата в любое время и в любом месте',
  topResources: 'Высочайшее качество',
  theTeacher: 'Преподаватель',
  allOf: 'всё',
  packUp: 'прекратить',
  general: 'Общий уровень',
  researchOriented: 'Ориентированный на исследования',
  applicationOriented: 'Ориентированный на приложения',
  openForRegistration: 'тренинги, вызывающие огромный интерес',
  // 学历
  doctor: 'профессор, доктор',
  master: 'магистр',
  undergraduate: 'бакалавр',
  juniorCollege: 'профессиональное училище',
  technicalSecondary: 'среднее профессиональное училище?',
  // 工作职称
  other: 'остальное',
  teachingAssistant: 'ассистент-лаборант',
  lecturer: 'лектор-преподаватель',
  seniorLecturer: 'старший преподаватель',
  assistantProfessor: 'помощник профессора',
  associateProfessor: 'доцент',
  professor: 'профессор',
  professorProfessors: 'профессор-лектор',
  honoraryProfessors: 'почётный профессор',
  learn: 'Учиться у ведущих ученых и практиков',
  relatedCourses:
    'Пройти курсы по педагогике и управлению образованием с использованием ИКТ, большим данным, искусственному интеллекту, облачным вычислениям и многому другому.',
  forge: 'Предоставлены ЮНЕСКО-ICHEI и IIOE',
  companionUnit: 'В партнерстве с:',
  // 任教的专业
  art: 'искусствоведение',
  pedagogy: 'педагогика',
  management: 'менеджмент',
  aLaw: 'правоведение, юриспруденция',
  literature: 'литература, гуманитарные науки',
  agronomy: 'агрономия',
  military: 'военная наука',
  philosophy: 'философия',
  computer: 'компьютерные и информационные науки',
  otherO: 'Другое',

  // 培训
  important:
    'Важно: Пожалуйста, подтвердите ваше ПОЛНОЕ ИМЯ, которое будет отображаться в сертификате',
  change: 'вы можете изменить имя , нажав кнопку “Изменить”',
  submit: 'Пожалуйста, нажмите "Отправить заявку", чтобы отправить',
  certificateName: 'указанное имя в сертификате',
  modify: 'исправить',
  confirm: 'подтвердить',
  submitApplication: 'подать заявку',
  submitBtn: 'подать, опубликовать',
  workFinish: 'домашняя работа окончена',
  pleaseInput: 'пожалуйста, введите содержание домашней работы',
  taskContent: 'содержание задания',
  beginToLearn: 'приступить к обучению',
  signUp: 'зарегистрируйтесь',
  classDetail: 'Общие сведения о курсе',
  courseOutline: 'Учебный план курса',
  learnContent: 'содержание курса',
  homework: 'Задание',
  freeClasses: 'Бесплатные курсы повышения квалификации в области ИКТ',
  custom: 'предназначенные для преподавателей высших учебных заведений',
  buildForManagers:
    'Для преподавателей и администраторов высшего образования со всего мира',
  combination:
    'Успешное внедрение и использование ИКТ и вспомогательных технологий в инклюзивном образовании требует их интеграции с соответствующими педагогическими подходами, используемыми хорошо подготовленными педагогами.',
  testReport: 'Всемирный доклад по мониторингу образования за 2020 г.',
  classTime: 'Дата начала регистрации',
  here: 'ЗДЕСЬ',
  click: 'Нажмите',
  understand: '，чтобы узнать о процессе регистрации',
  trainingInformation: 'Информация о тренингах',
  update: 'Обновить ваши знания и навыки',
  computing:
    'в области облачных вычислений, больших данных, Интернет вещей, искусственного интеллекта и т. д.',
  digital: 'Подготовиться к цифровой эпохе',
  blending: 'онлайн- и смешанного преподавания и обучения',
  earn: 'Получить сертификаты',
  taught:
    'о прохождении тренингов, проводимых ведущими учеными и практиками со всего мира',
  joinGlobal: 'Присоединиться к мировому сообществу',
  higher:
    'профессионалов высшего образования для получения совместных ресурсов',
  model: 'модуль',
  nameOfInstitution: 'название учреждения',
  circleImage: '@/assets/img/main/training/circle_en.e357800b.png',
  // 证书
  order: 'подтвердить заявку',
  issueTheCertificate:
    'Заявка на сертификат, которую вы подали, была получена, и мы выдадим сертификат в течение 4 недель после рассмотрения',
  emailDownload:
    'Электронный сертификат будет отправлен на ваш адрес электронной почты и доступен для загрузки',
  note: 'Примечание: Дизайн выданного сертификата будет немного отличаться в зависимости от обновления дизайна текущей версии сертификата',
  close: 'закрыть',
  submitAnApplication: 'подать заявку на сертификат',
  canNotEmpty: 'Не должно быть пустым',
  sureSubmit: 'Вы уверены, что готовы отправить задание?',
  sure: 'Подтвердите',
  cancle: 'Удалить',
  // 合作伙伴
  aboutUs: 'о нас',
  interest:
    'Если вы заинтересованы в сотрудничестве с IIOE, пожалуйста, нажмите',
  higherEducation: 'Высшие учебные заведения',
  institutions: 'Предприятия',
  // 公共
  watchTheVideo: 'Смотреть видеоролик',
  coursePlayer: 'Руководство по ресурсам курсов',
  nanShanDistrict:
    '№ 1088, бульвар Сюэюань, район Наньшань, Шэньчжэнь, провинция Гуандун, Китай',
  links: 'Ссылки',

  // 练习题
  choise: 'Выполните следующие многовариантные упражнения.  ',
  judge: 'Пожалуйста, определите, верны ли следующие утверждения.  ',
  pre: 'Предыдущее  ',
  nextQ: 'Следующее  ',
  right: 'Правда',
  wrong: 'Ложь'
}
