import { createStore, Store, useStore as useVuexStore } from 'vuex'
import { IRootState, IStoreType } from './types'
import login from './login/login'
import register from './register/register'
import nav from './nav/nav'
import course from './course/course'
import training from './training/training'
import partners from './partners/partners'
import profile from './profile/profile'
import lang from './lang/lang'

const store = createStore<IRootState>({
  state() {
    return {
      name: ''
    }
  },
  mutations: {},
  actions: {},
  modules: {
    login,
    register,
    nav,
    course,
    training,
    partners,
    profile,
    lang
  }
})

export function setupStore() {
  store.dispatch('login/loadLocalLogin')
}

export function useStore(): Store<IStoreType> {
  return useVuexStore()
}

export default store
