// service统一出口

import HHRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'

import Cookies from 'js-cookie'

const hhRequest = new HHRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,

  // 拦截器(可选)
  interceptors: {
    requestInterceptor: (config) => {
      // 携带 token 和 language 的拦截器
      const token = Cookies.get('userIdentity')
      // console.log('hhhhh++++++token', token)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      const lang = window.location.host === 'es.iioe.org' ? 'es' : 'ru'
      // const lang = 'es'
      config.headers['Accept-Language'] = lang
      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res) => {
      return res
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})

export default hhRequest
