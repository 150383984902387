import hhRequest from '../index'
import { IRegisterAccount, IRegisterResult } from './types'

// 接口
enum registerApi {
  AccountRegister = '/auth/register'
}

export function accountLoginRequest(account: IRegisterAccount) {
  return hhRequest.post<IRegisterResult>({
    url: registerApi.AccountRegister,
    data: account
  })
}
