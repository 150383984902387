import hhRequest from '../index'
import { IAccount } from './types'
import { ILoginState } from '@/store/login/types'

// 接口
enum loginApi {
  AccountLogin = '/auth/login'
}

export function accountLoginRequest(account: IAccount) {
  return hhRequest.post<ILoginState>({
    url: loginApi.AccountLogin,
    data: account
  })
}
