import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a0900e4e")
const _hoisted_1 = { class: "hh-form" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "verification" }
const _hoisted_4 = {
  key: 3,
  class: "select"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true),
      _renderSlot(_ctx.$slots, "message", {}, undefined, true)
    ]),
    _createVNode(_component_el_form, {
      class: "form",
      model: _ctx.modelValue
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItems, (item) => {
              return (_openBlock(), _createBlock(_component_el_col, _normalizeProps(_mergeProps({
                key: item.field
              }, item.field !== 'title' ? _ctx.colLayout : { span: 24 })), {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    style: _normalizeStyle(_ctx.itemStyle),
                    label: item.label ? _ctx.$filters.hh_t(item.label) : '',
                    "label-width": item.label ? '223px' : '0',
                    rules: item.rules,
                    prop: `${item.field}`
                  }, {
                    default: _withCtx(() => [
                      (item.title)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "sub-title",
                            style: _normalizeStyle({ width: _ctx.$isMobile ? '100%' : _ctx.titleWidth })
                          }, _toDisplayString(_ctx.$filters.hh_t(item.title) ? _ctx.$filters.hh_t(item.title) : ''), 5))
                        : _createCommentVNode("", true),
                      (item.type === 'input' || item.type === 'password')
                        ? (_openBlock(), _createBlock(_component_el_input, {
                            key: 1,
                            placeholder: 
                    item.placeholder === ' '
                      ? ' '
                      : _ctx.$filters.hh_t(item.placeholder)
                  ,
                            "show-password": item.type === 'password',
                            "model-value": _ctx.modelValue[`${item.field}`],
                            "input-style": { width: _ctx.$isMobile ? '97%' : item.width },
                            "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field))
                          }, null, 8, ["placeholder", "show-password", "model-value", "input-style", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true),
                      (item.type === 'verification')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_el_input, {
                                placeholder: 
                      item.placeholder === ' '
                        ? ' '
                        : _ctx.$filters.hh_t(item.placeholder)
                    ,
                                "model-value": _ctx.modelValue[`${item.field}`],
                                "input-style": { width: _ctx.$isMobile ? '97%' : '200px' },
                                class: "verification",
                                "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field))
                              }, null, 8, ["placeholder", "model-value", "input-style", "onUpdate:modelValue"]),
                              _createVNode(_component_el_button, {
                                onClick: _ctx.sendCode,
                                disabled: (_ctx.disabled = !_ctx.show) || _ctx.modelValue[`email`] === ''
                              }, {
                                default: _withCtx(() => [
                                  _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$filters.hh_t('sendVerificationCode')), 513), [
                                    [_vShow, _ctx.show]
                                  ]),
                                  _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.count) + "s" + _toDisplayString(' ' + _ctx.$filters.hh_t('toResend')), 513), [
                                    [_vShow, !_ctx.show]
                                  ])
                                ]),
                                _: 1
                              }, 8, ["onClick", "disabled"])
                            ]),
                            _createElementVNode("div", {
                              class: "line",
                              style: _normalizeStyle({
                    width: _ctx.$isMobile ? '97%' : item.width,
                    height: '1px',
                    backgroundColor: '#d8d8d8',
                    marginTop: '-1px'
                  })
                            }, null, 4)
                          ], 64))
                        : _createCommentVNode("", true),
                      (item.type === 'select')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_el_select, _mergeProps({
                              placeholder: 
                      item.placeholder === ' '
                        ? ' '
                        : _ctx.$filters.hh_t(item.placeholder)
                    
                            }, item.otherOptions, {
                              "model-value": _ctx.modelValue[`${item.field}`],
                              "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field)),
                              style: {
                      width: _ctx.$isMobile
                        ? '97%'
                        : (item.field === 'networkId' &&
                            _ctx.modelValue[`${item.field}`] === 0) ||
                          (item.field === 'discipline' &&
                            _ctx.modelValue[`${item.field}`] === 'otherO')
                        ? _ctx.$isMobile
                          ? '48%'
                          : '200px'
                        : item.width
                    }
                            }), {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (option) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    value: 
                        _ctx.$filters.hh_t(option.value)
                          ? _ctx.$filters.hh_t(option.value)
                          : option.value
                      ,
                                    key: 
                        _ctx.$filters.hh_t(option.value)
                          ? _ctx.$filters.hh_t(option.value)
                          : option.value
                      ,
                                    label: 
                        _ctx.$filters.hh_t(option.title)
                          ? _ctx.$filters.hh_t(option.title)
                          : option.title
                      
                                  }, null, 8, ["value", "label"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1040, ["placeholder", "model-value", "onUpdate:modelValue", "style"]),
                            (
                      (item.field === 'networkId' &&
                        _ctx.modelValue[`${item.field}`] === 0) ||
                      (item.field === 'discipline' &&
                        _ctx.modelValue[`${item.field}`] === 'otherO')
                    )
                              ? (_openBlock(), _createBlock(_component_el_input, {
                                  key: 0,
                                  type: "text",
                                  placeholder: _ctx.$filters.hh_t('nameOfInstitution'),
                                  "input-style": {
                      width: _ctx.$isMobile ? '48%' : '298px',
                      marginBottom: '1px',
                      marginLeft: '6px'
                    },
                                  "model-value": 
                      item.field === 'networkId'
                        ? _ctx.modelValue['networkName']
                        : _ctx.modelValue['discipline']
                    ,
                                  "onUpdate:modelValue": ($event: any) => (
                      _ctx.handleValueChange(
                        $event,
                        item.field === 'networkId'
                          ? 'networkName'
                          : 'discipline'
                      )
                    )
                                }, null, 8, ["placeholder", "input-style", "model-value", "onUpdate:modelValue"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (item.type === 'datepicker')
                        ? (_openBlock(), _createBlock(_component_el_date_picker, _mergeProps({
                            key: 4,
                            placeholder: 
                    item.placeholder === ' '
                      ? ' '
                      : _ctx.$filters.hh_t(item.placeholder)
                  ,
                            type: "date"
                          }, item.otherOptions, {
                            style: { width: _ctx.$isMobile ? '97%' : item.width },
                            "model-value": _ctx.modelValue[`${item.field}`],
                            "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field))
                          }), null, 16, ["placeholder", "style", "model-value", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["style", "label", "label-width", "rules", "prop"])
                ]),
                _: 2
              }, 1040))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}